import styled from 'styled-components';

interface ICFContainer {
  gap?: number;
  width?: string;
  noWrap?: boolean;
  height?: string;
}

const GridContainer = styled.div<{ templateColumns?: string }>`
  display: grid;
  grid-template-columns: ${({ templateColumns }) => templateColumns ?? '1fr 1fr 1fr 1fr'};
  grid-template-rows: fit-content, auto;
  gap: 30px;
  margin-bottom: 40px;
  align-items: start;
`;

const CFContainer = styled.div<ICFContainer>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: ${(p) => (p.gap || p.gap === 0 ? p.gap + 'px' : '30px')};
  width: ${(p) => p.width ?? '390px'};
  height: ${(p) => p.height};
`;

const RFContainer = styled.div<ICFContainer>`
  display: flex;
  flex-wrap: ${({ noWrap }) => (noWrap ? 'nowrap' : 'wrap')};
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: ${(p) => (p.gap || p.gap === 0 ? p.gap + 'px' : '12px')};
  width: ${(p) => p.width ?? '100%'};
  min-width: ${(p) => p.width ?? '390px'};
  height: ${(p) => p.height ?? false};
`;

const BCContainer = styled(RFContainer)`
  width: max-content;
  min-width: max-content;
`;

const RFRContainer = styled(RFContainer)`
  justify-content: flex-end;
`;

const RFSBContainer = styled(RFContainer)`
  flex-wrap: nowrap;
  justify-content: space-between;
`;

const CFLContainer = styled(CFContainer)`
  align-items: flex-start;
  margin-right: auto;
`;

const CFSBContainer = styled(CFContainer)`
  justify-content: space-between;
  min-height: calc(100vh - 80px);
`;

const CFRContainer = styled(CFContainer)`
  align-items: flex-end;
`;

const AuthContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0;
  height: 44px;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  border-bottom: 1px solid ${(p) => p.theme.color.gray[2]};

  &.fullWidth {
    width: 100%;
  }
`;

const LoaderContainer = styled.div`
  svg {
    stroke: ${(p) => p.theme.color.gray[9]};
  }
`;

const OverflowContainer = styled.div<{ height?: string; withBorder?: boolean }>`
  width: 100%;
  overflow: auto;

  ${({ height }) => height && `height: ${height}`};
  ${({ withBorder }) => withBorder && `border: 1px solid #ebeef1;`};
`;

const WContainer = styled.div<{ width?: string }>`
  width: ${({ width }) => width ?? '100%'};
`;

const RFRMContainer = styled(RFRContainer)<{ margin: string }>`
  ${({ margin }) => margin && `margin: ${margin}`}
`;

export {
  AuthContainer,
  GridContainer,
  CFContainer,
  CFLContainer,
  CFRContainer,
  CFSBContainer,
  LoaderContainer,
  InfoContainer,
  RFContainer,
  RFRContainer,
  RFSBContainer,
  OverflowContainer,
  BCContainer,
  WContainer,
  RFRMContainer,
};
