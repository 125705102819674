export const BREADCRUMB_EXCEPTIONS = [
  'alter-jobs',
  'batch-jobs',
  'create',
  'edit-dictionary',
  'edit-file',
  'edit-function',
  'edit-user',
  'invite-new-user',
  'new-custom-function',
  'reference',
  'update-file',
  'edit-bucket',
];
