import styled from 'styled-components';

const DropMenuContainer = styled.div`
  position: relative;
  width: max-content;
  cursor: pointer;
`;

const DropMenuHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
  color: ${(p) => p.theme.color.gray[0]};
  transition: all 0.2s linear;
  user-select: none;

  &:hover {
    text-decoration: underline;
  }

  &.bordered {
    color: ${(p) => p.theme.color.gray[6]};
    border: ${(p) => `1px solid ${p.theme.color.gray[3]}`};
    gap: 15px;
    padding: 7px 15px;
  }
`;

const DropListHeader = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  svg {
    fill: ${(p) => (p.isActive ? p.theme.color.blue[7] : p.theme.color.gray[7])};
    stroke: ${(p) => (p.isActive ? p.theme.color.blue[7] : p.theme.color.gray[7])};
  }

  &:hover {
    svg {
      fill: ${(p) => p.theme.color.gray[10]};
      stroke: ${(p) => p.theme.color.gray[10]};
    }
  }
`;

const DropMenuArrow = styled.svg<{ bordered?: boolean }>`
  width: ${(p) => (p.bordered ? '12px' : '10px')};
  height: ${(p) => (p.bordered ? '12px' : '6px')};
  fill: none;
  stroke: ${(p) => (p.bordered ? p.theme.color.gray[4] : p.theme.color.gray[0])};
  stroke-width: 1.4px;
  stroke-linecap: round;
  stroke-linejoin: round;
  transform: rotate(180deg);
  transition: all 0.2s linear;

  &.menu-opened {
    transform: rotate(360deg);
  }
`;

const DropMenuList = styled.ul<{ bordered?: boolean }>`
  position: absolute;
  top: ${(p) => (p.bordered ? '38px' : '24px')};
  right: 0;
  box-sizing: border-box;
  width: fit-content;
  min-width: 182px;
  background-color: ${(p) => p.theme.color.gray[0]};
  border-radius: 2px;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0px 0px 12px rgba(82, 82, 88, 0.16);

  &.menu-opened {
    visibility: visible;
    opacity: 1;
    z-index: 999;
  }
`;

const DropMenuListItem = styled.li<{ isDelete?: boolean }>`
  display: inline-flex;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 8px 20px;
  color: ${({ theme, isDelete }) => (!isDelete ? theme.color.gray[9] : theme.color.danger[2])};

  svg {
    stroke: ${(p) => p.theme.color.gray[9]};
  }

  &:hover {
    background: ${(p) => p.theme.color.gray[1]};
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }
`;

export {
  DropMenuContainer,
  DropMenuHeader,
  DropListHeader,
  DropMenuArrow,
  DropMenuList,
  DropMenuListItem,
};
