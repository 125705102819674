import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

* {
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  position: relative;
}

button {
  border: none;
  background-color: transparent;  
  padding: 0;
  cursor: pointer;
  outline: none;

  &:disabled {
    cursor: not-allowed;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}

form {
  width: 100%;
}

textarea, input {
    font-family: 'Inter', sans-serif;
    outline: none;
}
::-webkit-input-placeholder {
    font-family: 'Inter', sans-serif;
}
:-moz-placeholder {
    font-family: 'Inter', sans-serif;
}
::-moz-placeholder {
    font-family: 'Inter', sans-serif;
}
:-ms-input-placeholder {
    font-family: 'Inter', sans-serif;
}

.monaco-editor .margin  {
  width: 36px !important;
  background: #f7f7f7 !important;
}

.monaco-editor .margin .margin-view-overlays  {
  width: 36px !important;  
}

.monaco-editor .margin .line-numbers {  
  width: 36px !important;  
  font-size: 11px !important;
  text-align: center !important;
}

.monaco-editor .current-line {  
  border: none !important;
}

.monaco-editor .monaco-scrollable-element.editor-scrollable.vs.mac {  
   left: 55px !important
}

.not-overflowed-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.top-tooltip {
  max-width: 390px;
  padding: 5px 20px !important;
  margin: 0 !important;
}

`;

export default GlobalStyle;
